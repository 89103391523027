import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7106183725223'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF WAIST WIDTH": [
            "",
            "12¼/31",
            "13/33",
            "13¾/35",
            "14½/37",
            "15¼/39",
            "16/41",
            "17/43",
            "17¾/45"
        ],
        "HALF HIPS WIDTH": [
            "",
            "18/46",
            "18¾/48",
            "19½/50",
            "20½/52",
            "21¼/54",
            "22/56",
            "22¾/58",
            "23½/60"
        ],
        "INSIDE LEG LENGTH": [
            "",
            "29¼/74,5",
            "29½/75",
            "30/76",
            "30/76,5",
            "30¼/77",
            "30¾/78",
            "31/79",
            "31/79"
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Bedford Sweatpant" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify